<template>
  <validation-observer ref="validacao">
    <cadastro-base
      :salvar="salvar"
      :cancelar="cancelar"
    >
      <template slot="conteudo">
        <b-form-checkbox
          v-model="form.ativa"
          name="check-button"
          style="float:right;"
          switch
        >
          <span style="font-size: 14px">Empresa ativa</span>
        </b-form-checkbox>

        <b-form-checkbox
          v-model="form.favorita"
          name="check-button"
          style="float:right;"
          class="mr-5"
          switch
        >
          <span style="font-size: 14px">Empresa favorita</span>
        </b-form-checkbox>

        <b-tabs content-class="mt-3">
          <b-tab
            title="Grupo geral"
            no-body
            active
          >
            <secao-geral
              v-model="form"
              @buscar-empresa="form = $event"
            />
            <secao-contador v-model="form.contador" />
            <secao-cnae v-model="form" />
            <b-row>
              <b-col cols="12">
                <divisoria-descricao
                  class="mt-2"
                  :titulo="'Endereço'"
                  :descricao="'Adicione as informações sobre o endereço e contato desta empresa'"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <endereco v-model="form.endereco" />
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            no-body
            title="Sócios"
          >
            <secao-socio v-model="form" />
          </b-tab>
          <b-tab
            no-body
            title="Conf. rotinas e-CAC"
          >
            <secao-conf-ecac v-model="form" />
          </b-tab>
        </b-tabs>
      </template>
    </cadastro-base>
  </validation-observer>
</template>

<script>

import {
  defineComponent, ref,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import {
  required,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useAbility from '@/libs/acl/ability'
// eslint-disable-next-line import/no-cycle
import useEmpresa from './useEmpresa'

export default defineComponent({
  name: 'EmpresaCadastro',
  components: {
    ValidationProvider,
    ValidationObserver,
    SecaoGeral: () => import('../secao-geral/SecaoGeral.vue'),
    SecaoCnae: () => import('../secao-cnae/SecaoCnae.vue'),
    SecaoSocio: () => import('../secao-socios/SecaoSocios.vue'),
    SecaoConfEcac: () => import('../configuracoes-ecac/SecaoConfEcac.vue'),
    SecaoContador: () => import('../secao-contador/SecaoContador.vue'),
    CadastroBase: () => import('@pilar/components/cadastro-base/CadastroBase.vue'),
    Endereco: () => import('@pilar/components/endereco/Endereco.vue'),
    DivisoriaDescricao: () => import('@pilar/components/divisoria-descricao/DivisoriaDescricao.vue'),
  },
  setup() {
    const { route } = useRouter()
    const modoEdicao = route.value.path.indexOf('editar') > 0
    const validacao = ref(null)

    const {
      salvar,
      carregar,
      novo,
      cancelar,
      form,
    } = useEmpresa(modoEdicao, validacao, useAbility)
    if (modoEdicao) {
      carregar(route.value.params.id)
    } else {
      novo()
    }

    return {
      required,
      salvar,
      carregar,
      novo,
      cancelar,
      form,
      validacao,
    }
  },
})
</script>
